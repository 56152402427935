.Service {

  &__content {
    height         : 542px;
    display        : flex;
    flex-direction : row;

    @media(max-width : 991px) {
      height         : auto;
      flex-direction : column;
    }
    @media(max-width : 767px) {
      height  : auto;
    }

    &__a {
      height : 542px;
      width  : 50%;

      @media(max-width : 991px) {
        width : 100%;
      }
      @media(max-width : 767px) {
        height  : 269px;
      }

      &__image {
        height              : 542px;
        background-size     : cover;
        background-position : center center;

        @media(max-width : 767px) {
          height  : 269px;
        }
      }
    }

    &__b {
      width          : 50%;
      padding-top    : 79px;
      padding-bottom : 58px;

      @media(max-width : 991px) {
        width : 100%;
      }
      @media(max-width : 767px) {
        padding-top    : 72px;
        padding-bottom : 43px;
      }

      &__title {
        line-height    : 39px;
        font-weight    : 500;
        font-size      : 30px;
        letter-spacing : 1px;
        color          : #ffffff;
        margin-bottom  : 74px;
        text-align     : center;

        @media(max-width : 767px) {
          margin-bottom: 56px;
        }

      }

      &__text {
        font-size     : 18px;
        line-height   : 1.9;
        color         : #ffffff;
        padding-left  : 37px;
        padding-right : 37px;
        text-align    : center;
        margin-bottom : 92px;
        height        : 130px;
        text-overflow : ellipsis;
        overflow      : hidden;
        position      : relative;
        display       : block;

        p {
          margin-bottom : 0;
        }

        ul {
          width   : auto;
          display : inline-block;
        }

        @media(max-width : 767px) {
          margin-bottom: 63px;
        }

      }

    }

  }

  &-reverse {

    &__content {
      height         : 542px;
      display        : flex;
      flex-direction : row-reverse;

      @media(max-width : 991px) {
        height         : auto;
        flex-direction : column;
      }
      @media(max-width : 767px) {
        height  : auto;
      }
    }
  }
}
