.Banner_media {

  &__content {
    max-height  : 607px;
    height      : auto;
    position    : relative;
    overflow    : hidden;
    display     : flex;
    align-items : center;

    @media(max-width : 767px) {
      height : 329px;
    }

    &-image {
      height              : 607px;
      background-size     : cover;
      background-position : center center;

      @media(max-width : 767px) {
        height : 329px;
      }
    }

    &-video {
      min-width       : 100%;
      min-height      : 100%;
      width           : auto;
      height          : auto;
      z-index         : 0;
      overflow        : hidden;
      display         : flex;
      justify-content : center;
      align-items     : center;

      @media(max-width : 767px) {
        position : absolute;
        top      : 0px;
        left     : 0px;
      }
      @media(max-width : 649px) {
        position : absolute;
        top      : 0px;
        left     : -55px;
      }
      @media(max-width : 559px) {
        position : absolute;
        top      : 0px;
        left     : -100px;
      }
      @media(max-width : 479px) {
        position : absolute;
        top      : 0px;
        left     : -150px;
      }
    }
  }
}