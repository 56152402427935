/*
* Vendor extensions
*/
/* GLOBAL STYLES */
/* Padding below the footer and lighter body text */
body {
  color: #5a5a5a; }

/* CUSTOMIZE THE NAVBAR  */
/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20; }

/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper > .container {
  padding-right: 0;
  padding-left: 0; }

.navbar-wrapper .navbar {
  padding-right: 15px;
  padding-left: 15px; }

.navbar-wrapper .navbar .container {
  width: auto; }

/* CUSTOMIZE THE CAROUSEL  */
/* Carousel base class */
.carousel {
  height: 500px; }

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  top: 5%;
  text-align: left;
  left: 15%; }

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #777; }

.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px; }

/* MARKETING CONTENT */
/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center; }

.marketing h2 {
  font-weight: normal; }

.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px; }

/* Featurettes */
.featurette-divider {
  margin: 80px 0;
  /* Space out the Bootstrap <hr> more */ }

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px; }

/* RESPONSIVE CSS */
@media (min-width: 768px) {
  /* Navbar positioning */
  .navbar-wrapper {
    margin-top: 20px; }
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px; }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0; }
  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px; }
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 1.4; }
  .featurette-heading {
    font-size: 50px; } }

@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 120px; } }

/* Contact page */
.contact-header-container {
  max-width: 800px;
  color: #333; }

.contact-container {
  color: #333; }

.contact-text-alignment {
  text-align: center; }

.header {
  color: #333;
  font-size: 27px;
  padding: 10px; }

.bigicon {
  font-size: 35px;
  color: #333; }

.background-contact-form {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: grey;
  color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.Wysiwyg-text-image__text p {
  margin: 0 0 5px;
  font-family: 'Roboto'; }

/*
 * Globals
 * Onderaan de pagina staan alle eigengemaakte classes
 */
/* Links */
a,
a:focus,
a:hover {
  color: #fff; }

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #333;
  text-shadow: none;
  /* Prevent inheritence from `body` */
  background-color: #fff;
  border: 1px solid #fff; }

/* Fullpage */
#fullpage {
  display: block;
  width: 100%;
  height: 100vh; }

.about_welcome {
  background: #333;
  height: 100%;
  width: 100%;
  position: absolute; }

.horizontal-align {
  color: #fff; }

.arrow-down {
  position: relative;
  text-align: center;
  font-size: 24px;
  top: 95%;
  color: #fff;
  z-index: 5; }

@media (max-width: 768px) {
  .arrow-down {
    top: 92.5%; } }

/*
 * Base structure
 */
/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper-gallerypages {
  color: #fff;
  text-align: center;
  display: table;
  width: 100%;
  height: 100%;
  /* For at least Firefox */
  min-height: 100%;
  background-color: #fff; }

.cover-container {
  margin-right: auto;
  margin-left: auto; }

.masthead {
  position: absolute;
  color: #fff;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 10; }
  .masthead a {
    color: white; }

/* Padding for spacing */
.inner {
  padding: 30px; }

/*
 * Header
 */
/* Responsive Nav */
.masthead-brand {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10%; }

.masthead-nav {
  margin-right: 10%;
  float: right; }

.masthead-nav > li {
  display: inline-block; }

.masthead-nav > li + li {
  margin-left: 20px; }

.masthead-nav > li > a {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  /* IE8 proofing */
  color: rgba(255, 255, 255, 0.75);
  border-bottom: 2px solid transparent;
  padding: 5px 10px; }

.masthead-nav > li > a:hover,
.masthead-nav > li > a:focus {
  background-color: transparent;
  border-bottom-color: #a9a9a9;
  border-bottom-color: rgba(255, 255, 255, 0.25); }

.masthead-nav > .active > a,
.masthead-nav > .active > a:hover,
.masthead-nav > .active > a:focus {
  color: #fff;
  border-bottom-color: #fff; }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  border-color: #fff; }

@media (min-width: 768px) {
  .masthead-brand {
    float: left; } }

/*
 * Cover
 */
.cover {
  padding: 0 20px; }

.cover .btn-lg {
  margin-top: 75px;
  padding: 10px 20px;
  font-weight: bold; }

/*
 * Footer
 */
.mastfoot {
  color: #999;
  /* IE8 proofing */
  color: rgba(255, 255, 255, 0.5); }

/*
 * Affix and center
 */
@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    margin-top: 0;
    width: 100%; }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle; }
  /* Handle the widths */ }

/* Eigengemaakte Classes */
.Masthead__menu-items__item__link {
  color: white; }
  .Masthead__menu-items__item__link:hover {
    color: white;
    text-decoration: none; }

/* Gallery */
.image-gallery-information {
  text-align: left; }

/* Small Images */
.small-images-gallery {
  margin-top: -50px; }

@media (max-width: 360px) {
  .gallerypages {
    max-width: 300px; } }

/* Button Next to Small Images */
.gallery-button {
  text-align: left;
  margin-top: -50px; }

/* Little Responsive edit on the Button */
@media (max-width: 768px) {
  .gallery-button {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center; } }

/* Bootstrap table */
.gallery-text {
  font-size: 16px; }

.table-gallery {
  font-size: 10px;
  color: white;
  background-color: grey; }

.floating-pictures {
  margin-top: -90px;
  position: relative;
  z-index: 1; }

/* Homepage */
/* Sticky Footer Scooped*/
body {
  background-color: #ffffff; }

.masthead-brand {
  margin-top: 4px; }

.page-header-container {
  margin-top: 25px;
  max-width: 800px; }

.Utils--Center--Align {
  text-align: center; }

.page-header-content {
  margin-top: 20%;
  text-align: center; }

.page-content {
  text-align: center; }

.more-about-us-div {
  text-align: center;
  margin-top: 150px; }

.more-about-us {
  width: auto;
  margin-top: 150px;
  font-size: 22px;
  color: #0000ed;
  border-bottom-color: #0000ed;
  border-bottom: 2px solid; }

/* IcoMOON icons */
.icon-share:before {
  content: "\e900"; }

.icon-catalogus:before {
  content: "\e0e0"; }

.icon-share {
  color: white;
  margin-right: 25px;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

/* Marketing Featurettes */
.gallerypages {
  margin-top: 150px;
  margin-bottom: 100px; }

.gallerypagescontent {
  color: #333;
  border: 2px solid #333;
  padding-left: 0;
  padding-right: 0; }

/*gallery */
.image-with-bar {
  margin-bottom: 30px;
  overflow-x: hidden;
  overflow-y: auto; }

.image-info {
  padding: 15px 20px;
  background: #333;
  font-family: DecimaNova, Arial, sans-serif;
  color: grey;
  max-width: 360px; }

@media (max-width: 991px) {
  .hidden--search {
    display: none; } }

@media (max-width: 991px) {
  .show--search {
    display: block; } }

@media (max-width: 360px) {
  .cover-heading {
    margin-top: 70%;
    font-size: 28px; } }

@media (max-width: 768px) {
  .cover-heading {
    margin-top: 50%; }
  .image-with-bar {
    text-align: center; }
  .image-info {
    margin-left: auto;
    margin-right: auto; } }

.image-info h3 {
  margin-top: 0; }

/* Carousel slider */
#myCarousel {
  margin-top: 50px; }

.carousel-text-container {
  width: 70%; }

.Footer__container__item {
  text-align: left !important;
  color: #333; }

.Footer__container__menu__link {
  color: #333; }

.Footer__container__menu__register {
  margin-bottom: 20px;
  background-color: #222222;
  padding: 20px;
  font-size: 20px; }

/* Content page */
.banner-content-button {
  text-align: center !important; }

.bannerjumbotron {
  top: 100px;
  position: relative;
  background-color: transparent;
  background-image: url("/images/hvdk.png");
  background-repeat: no-repeat;
  background: cover;
  height: 600px;
  width: 100%;
  margin: 0 auto;
  resize: both;
  margin-bottom: 200px; }

.jumbotroncolor {
  padding: 20px;
  resize: both;
  margin: 0 auto;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  height: 600px; }

.bannerjumbotron h2, .bannerjumbotron p {
  text-align: center;
  color: #fff;
  width: 100%; }

.bannerjumbotron p {
  position: relative;
  margin-bottom: 50px; }

.bannerjumbotron h2 {
  margin-top: 7.5%; }

@media (max-width: 768px) {
  .bannerjumbotron {
    height: 300px; }
  .jumbotroncolor {
    height: 400px; } }

@media (max-width: 1024px) {
  .bannerjumbotron {
    height: 400px; }
  .jumbotroncolor {
    height: 400px; } }

.banner-content-button {
  margin: 0;
  background: white;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.contenth2 {
  right: 5% !important;
  bottom: 5% !important; }

/* Fullpage image */
.fullpagecover {
  height: 100vh;
  resize: both;
  margin: 0 auto;
  position: relative; }

.fullpageimage-image {
  height: 100vh; }

@media (max-width: 1024px) {
  .fullpagecover {
    height: 75vh; }
  .fullpageimage-image {
    height: 75vh; } }

@media (max-width: 768px) {
  .fullpagecover {
    height: 50vh; }
  .fullpageimage-image {
    height: 50vh; } }

@media (max-width: 480px) {
  .fullpagecover {
    height: 30vh; }
  .fullpageimage-image {
    height: 30vh; } }

.arrow-container {
  min-height: 20px;
  text-align: center; }

@keyframes moving {
  0% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0);
    color: transparent; } }

@-webkit-keyframes moving {
  0% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0);
    color: transparent; } }

.moving {
  -webkit-animation: moving 2s infinite;
  animation: moving 2s infinite; }

.pictures-column-container {
  position: relative;
  width: 100%;
  height: 450px; }

.pictures-column-container .container {
  height: 100%; }

.gallery-row {
  padding-bottom: 30px; }

.carousel-buttons {
  position: absolute;
  top: 0;
  bottom: 0; }

.carousel-right {
  right: 100px; }

.carousel-left {
  left: 100px; }

/*
* Utils
*/
.Utils__alignment--center {
  text-align: center; }

.Utils__alignment--left {
  text-align: left; }

.Utils__alignment--right {
  text-align: right; }

.Utils__float--left {
  float: left; }

.Utils__float--right {
  float: right; }

/**
 * Epic vertical container, only requirement is that the parent of this util has a position relative or absolute : )
 */
.Utils__vertical-container {
  width: 100%;
  height: 100%; }

.Utils__vertical-container__content {
  width: 100%;
  height: 100%;
  display: table;
  -webkit-backface-visibility: hidden; }

.Utils__vertical-align--middle {
  display: table-cell;
  vertical-align: middle; }

.Utils__horizontal-align--center {
  text-align: center; }

.Utils__background-size--cover {
  background-size: cover; }

.Utils__display--flex {
  display: flex; }

.Utils__display--none {
  display: none; }

.Utils__opacity--none {
  opacity: 0; }

.Utils__flex__horizontal--center {
  justify-content: center; }

.Utils__flex__vertical--center {
  align-items: center; }

.Utils__flex__vertical--start {
  align-self: flex-start; }

.Utils__flex__vertical--end {
  align-self: flex-end; }

.Utils__display-flex--1 {
  flex: 1; }

.Utils__display-flex--2 {
  flex: 2; }

.Utils__display-flex--3 {
  flex: 3; }

.Utils__display-flex--4 {
  flex: 4; }

.Utils__display-flex--6 {
  flex: 6; }

.Utils__display-flex--8 {
  flex: 8; }

.Utils__display-flex--10 {
  flex: 10; }

.Utils__display-flex--12 {
  flex: 12; }

.Utils__menu__offset {
  padding-top: 70px; }

.Utils__Footer--sticky {
  position: fixed;
  bottom: 0px; }

.Utils__absolute {
  position: absolute; }

/* 
* Global styling
*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?1tn30a");
  src: url("../fonts/icomoon.eot?1tn30a#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?1tn30a") format("woff2"), url("../fonts/icomoon.ttf?1tn30a") format("truetype"), url("../fonts/icomoon.woff?1tn30a") format("woff"), url("../fonts/icomoon.svg?1tn30a#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-close:before {
  content: "\e900"; }

.icon-menu:before {
  content: "\e901"; }

/*
* Components
*/
/*
* Client-specific styling
*/
.ip-header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #f1f1f1; }
  .ip-header .ip-loader {
    bottom: 20%; }
  .ip-header .ip-inner {
    display: block;
    margin: 0 auto; }
  .ip-header .ip-logo svg {
    min-width: 320px;
    max-width: 480px;
    width: 25%; }
  .ip-header .ip-logo svg path {
    fill: #ef6e7e; }
  .ip-header .ip-loader svg path {
    fill: none;
    stroke-width: 6; }
  .ip-header .ip-loader svg path.ip-loader-circlebg {
    stroke: #ddd; }
  .ip-header .ip-loader svg path.ip-loader-circle {
    -webkit-transition: stroke-dashoffset 0.2s;
    transition: stroke-dashoffset 0.2s;
    stroke: #ef6e7e; }

.ip-logo,
.ip-loader {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: default;
  pointer-events: none; }

.ip-logo {
  top: 0;
  height: 100%;
  -webkit-transform: translate3d(0, 25%, 0);
  transform: translate3d(0, 25%, 0); }

/* Animations */
/* Initial animation of header elements */
.ip-container.loading .ip-logo,
.ip-container.loading .ip-loader {
  opacity: 1;
  -webkit-animation: animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  animation: animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both; }

.ip-container.loading .ip-loader {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

@-webkit-keyframes animInitialHeader {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 800px, 0); } }

@keyframes animInitialHeader {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 800px, 0);
    transform: translate3d(0, 800px, 0); } }

/* Header elements when loading finishes */
.ip-container.loaded .ip-logo,
.ip-container.loaded .ip-loader {
  opacity: 1; }

.ip-container.loaded .ip-logo {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-animation: animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards; }

@-webkit-keyframes animLoadedLogo {
  to {
    -webkit-transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1); } }

@keyframes animLoadedLogo {
  to {
    -webkit-transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
    transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1); } }

.ip-container.loaded .ip-loader {
  -webkit-animation: animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards; }

@-webkit-keyframes animLoadedLoader {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1); } }

@keyframes animLoadedLoader {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
    transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1); } }

/* Change the color of the logo */
.ip-container.loaded .ip-logo svg path {
  -webkit-transition: all 0.5s ease 0.3s;
  transition: all 0.5s ease 0.3s;
  fill: #fff; }

/* Header animation when loading finishes */
.ip-container.loaded .ip-header {
  -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards; }

@-webkit-keyframes animLoadedHeader {
  to {
    -webkit-transform: translate3d(0, -100%, 0); } }

@keyframes animLoadedHeader {
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

/* Change layout class for header */
.layout-switch .ip-header {
  position: absolute; }

/* No JS */
.no-js .ip-header {
  position: relative;
  min-height: 0px; }

.no-js .ip-header .ip-logo {
  margin-top: 20px;
  height: 180px;
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

.no-js .ip-header .ip-logo svg path {
  fill: #fff; }

/* Media queries */
@media screen and (max-width: 45em) {
  .ip-main h2 {
    font-size: 2.25em;
    font-size: 10vw; }
  .box {
    width: 100%; } }

body {
  width: 100%;
  height: 100%; }

.slideout-menu {
  background-color: #f08a01;
  position: fixed;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: none;
  box-shadow: -1px 0px 15px 0px rgba(0, 0, 0, 0.5); }

.fa-angle-down {
  margin-top: 10px; }

#slideout-menu--languages {
  background-color: #f08a01;
  position: absolute;
  right: -275px;
  top: 0;
  width: 275px;
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  display: none;
  z-index: 5; }

.slideout-menu--open--languages:hover {
  cursor: pointer; }

.slideout-panel-darkness {
  position: absolute;
  z-index: 1000;
  width: 102%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); }

.slideout-panel {
  position: relative;
  z-index: 3;
  background-color: #ffffff; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden; }

.slideout-open .slideout-menu {
  display: block; }

.slideout-menu__header {
  width: 100%;
  font-size: 15px;
  color: #ffffff;
  text-align: right;
  padding-right: 15px;
  height: 50.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  text-transform: uppercase; }

.slideout-menu__bottom {
  text-transform: uppercase;
  font-size: 15px;
  color: #ffffff;
  text-align: right;
  padding-right: 15px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.3); }

.mobile-back {
  margin-right: 5px; }

.icon-close {
  font-size: 14px;
  vertical-align: -1px;
  position: relative;
  font-weight: 400; }

.icon-close:hover {
  cursor: pointer; }

.slideout-menu__items {
  text-decoration: none;
  width: 100%;
  text-align: right;
  padding-right: 15px;
  text-transform: uppercase; }

.slideout-menu__item {
  list-style-type: none;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1px; }

.slideout-menu__item__link {
  word-wrap: break-word;
  color: white; }
  .slideout-menu__item__link:hover {
    color: white;
    text-decoration: none; }

.slideout-menu__item:last-child {
  padding-bottom: 70px; }

.slideout-menu__item:hover {
  color: #ffa726; }

.slideout__menu__item--active {
  color: #ffa726; }

.Cookies {
  position: fixed;
  bottom: 0;
  z-index: 9001;
  width: 100%;
  padding: 2em;
  background-color: rgba(64, 64, 64, 0.8);
  text-align: center;
  color: white;
  text-shadow: 0 0 4px #000; }

.Cookies__accept-button {
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  color: #fff; }
  .Cookies__accept-button:hover {
    background-color: #fff; }
  .Cookies__accept-button:focus {
    color: #fff; }
  .Cookies__accept-button:hover {
    color: #000; }

.container,
.container-fluid {
  padding-top: 15px;
  padding-bottom: 40px;
  font-family: Roboto;
  font-weight: 400; }
  .container > .row > div,
  .container-fluid > .row > div {
    padding-left: 40px;
    padding-right: 40px; }
  @media (max-width: 991px) {
    .container,
    .container-fluid {
      padding-top: 5px;
      padding-bottom: 20px; }
      .container > .row > div,
      .container-fluid > .row > div {
        padding-left: 20px;
        padding-right: 20px; } }

p a,
ul a {
  color: #f08a01; }

.Button_std__outer {
  display: flex;
  justify-content: center; }
  .Button_std__outer:hover {
    text-decoration: none; }

.Button_std__inner {
  background: #f08a01;
  padding-left: 47px;
  padding-right: 47px;
  height: 60px;
  display: inline-block;
  transition: all 0.5s ease-in-out; }
  .Button_std__inner:hover {
    text-decoration: none;
    background: #c37000; }
  @media (max-width: 991px) {
    .Button_std__inner {
      padding-left: 27px;
      padding-right: 27px; } }
  .Button_std__inner__text {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.6px;
    color: #ffffff;
    cursor: pointer;
    background: none;
    border: none;
    outline: 0;
    text-align: center;
    justify-content: center; }
    .Button_std__inner__text:hover {
      text-decoration: none; }

.icon-menu {
  color: #2a384e;
  cursor: pointer; }

.icon-menu:hover {
  color: #f08a01;
  cursor: pointer; }

#slideout-menu {
  background-color: #2a384e; }
  #slideout-menu .slideout-menu__items {
    padding-right: 0; }
  #slideout-menu .slideout-menu__item {
    padding-right: 18px; }
    #slideout-menu .slideout-menu__item a {
      color: #fff;
      display: block;
      padding-top: 15px; }
  #slideout-menu .slideout__menu__item--active a {
    color: #f08a01; }
  #slideout-menu .mobile-back {
    cursor: pointer; }
  #slideout-menu .mobile-back:hover {
    color: #f08a01; }

.ip-header {
  background: #f08a01;
  display: flex;
  align-items: center; }

.ip-logo-holder {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  height: auto;
  justify-content: center; }

.ip-logo {
  height: auto;
  width: 40%;
  position: relative;
  display: inline-block; }
  @media (max-width: 991px) {
    .ip-logo {
      width: 50%; } }
  @media (max-width: 767px) {
    .ip-logo {
      width: 60%; } }

.ip-logo, .ip-loader {
  opacity: 1; }

.ip-header .ip-loader svg path.ip-loader-circle {
  stroke: #fff; }

.ip-main h2 {
  color: #fff; }

.Topbar__content {
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .Topbar__content__logo-holder {
    max-width: 58%; }
  .Topbar__content__logo {
    height: 41px;
    width: 360px; }
    .Topbar__content__logo img {
      max-height: 35px; }
  .Topbar__content__menu {
    height: 41px;
    min-width: 40px;
    display: flex; }
    .Topbar__content__menu ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      align-items: center;
      margin: 0; }
      .Topbar__content__menu ul li {
        padding-left: 60px;
        list-style: none; }
        .Topbar__content__menu ul li a {
          font-size: 16px;
          color: rgba(53, 71, 98, 0.5); }
        .Topbar__content__menu ul li a:hover {
          color: #354762;
          text-decoration: none; }
      .Topbar__content__menu ul li.active a {
        color: #f08a01; }
    .Topbar__content__menu__telephone {
      font-size: 16px;
      color: #f08a01; }

.Banner_media__content {
  max-height: 607px;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .Banner_media__content {
      height: 329px; } }
  .Banner_media__content-image {
    height: 607px;
    background-size: cover;
    background-position: center center; }
    @media (max-width: 767px) {
      .Banner_media__content-image {
        height: 329px; } }
  .Banner_media__content-video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px) {
      .Banner_media__content-video {
        position: absolute;
        top: 0px;
        left: 0px; } }
    @media (max-width: 649px) {
      .Banner_media__content-video {
        position: absolute;
        top: 0px;
        left: -55px; } }
    @media (max-width: 559px) {
      .Banner_media__content-video {
        position: absolute;
        top: 0px;
        left: -100px; } }
    @media (max-width: 479px) {
      .Banner_media__content-video {
        position: absolute;
        top: 0px;
        left: -150px; } }

.General_title_text__content {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 110px;
  flex-direction: column; }
  @media (max-width: 767px) {
    .General_title_text__content {
      padding-top: 56px;
      padding-bottom: 60px; } }
  .General_title_text__content__title {
    display: block;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 86px; }
  .General_title_text__content__holder_text {
    display: flex;
    justify-content: center; }
    .General_title_text__content__holder_text__text {
      font-size: 18px;
      line-height: 1.9;
      color: #ffffff;
      width: 50%; }
      @media (max-width: 767px) {
        .General_title_text__content__holder_text__text {
          width: auto;
          padding-left: 22px;
          padding-right: 22px; } }
      .General_title_text__content__holder_text__text ul {
        width: auto;
        display: inline-block; }
      .General_title_text__content__holder_text__text p {
        margin-bottom: 0; }
      .General_title_text__content__holder_text__text a:hover {
        color: #f08a01 !important; }

.Service__content {
  height: 542px;
  display: flex;
  flex-direction: row; }
  @media (max-width: 991px) {
    .Service__content {
      height: auto;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .Service__content {
      height: auto; } }
  .Service__content__a {
    height: 542px;
    width: 50%; }
    @media (max-width: 991px) {
      .Service__content__a {
        width: 100%; } }
    @media (max-width: 767px) {
      .Service__content__a {
        height: 269px; } }
    .Service__content__a__image {
      height: 542px;
      background-size: cover;
      background-position: center center; }
      @media (max-width: 767px) {
        .Service__content__a__image {
          height: 269px; } }
  .Service__content__b {
    width: 50%;
    padding-top: 79px;
    padding-bottom: 58px; }
    @media (max-width: 991px) {
      .Service__content__b {
        width: 100%; } }
    @media (max-width: 767px) {
      .Service__content__b {
        padding-top: 72px;
        padding-bottom: 43px; } }
    .Service__content__b__title {
      line-height: 39px;
      font-weight: 500;
      font-size: 30px;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 74px;
      text-align: center; }
      @media (max-width: 767px) {
        .Service__content__b__title {
          margin-bottom: 56px; } }
    .Service__content__b__text {
      font-size: 18px;
      line-height: 1.9;
      color: #ffffff;
      padding-left: 37px;
      padding-right: 37px;
      text-align: center;
      margin-bottom: 92px;
      height: 130px;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      display: block; }
      .Service__content__b__text p {
        margin-bottom: 0; }
      .Service__content__b__text ul {
        width: auto;
        display: inline-block; }
      @media (max-width: 767px) {
        .Service__content__b__text {
          margin-bottom: 63px; } }

.Service-reverse__content {
  height: 542px;
  display: flex;
  flex-direction: row-reverse; }
  @media (max-width: 991px) {
    .Service-reverse__content {
      height: auto;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .Service-reverse__content {
      height: auto; } }

.General_text_cta__content {
  padding: 80px 34px 93px 34px; }
  @media (max-width: 767px) {
    .General_text_cta__content {
      padding-top: 53px;
      padding-bottom: 39px; } }
  .General_text_cta__content__text {
    text-align: center;
    line-height: 1.9;
    font-size: 30px;
    letter-spacing: 1px;
    color: #ffffff;
    margin-bottom: 91px; }
    .General_text_cta__content__text ul {
      width: auto;
      display: inline-block; }
    .General_text_cta__content__text p {
      margin-bottom: 0; }
    .General_text_cta__content__text a:hover {
      color: #f08a01 !important; }
    @media (max-width: 767px) {
      .General_text_cta__content__text {
        margin-bottom: 60px; } }

.General_text_full__content {
  padding-top: 84px;
  padding-bottom: 78px;
  text-align: center; }
  @media (max-width: 767px) {
    .General_text_full__content {
      padding-top: 70px;
      padding-bottom: 69px; } }
  .General_text_full__content__holder_text {
    display: flex;
    justify-content: center; }
    .General_text_full__content__holder_text__text {
      width: 50%;
      line-height: 1.9;
      font-size: 30px;
      letter-spacing: 1px;
      color: #ffffff; }
      .General_text_full__content__holder_text__text ul {
        width: auto;
        display: inline-block; }
      .General_text_full__content__holder_text__text p {
        margin-bottom: 0; }
      .General_text_full__content__holder_text__text a:hover {
        color: #f08a01 !important; }
      @media (max-width: 767px) {
        .General_text_full__content__holder_text__text {
          width: 80%;
          line-height: 1.5; } }

.General_list_hori__content ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 0; }
  @media (max-width: 991px) {
    .General_list_hori__content ul {
      flex-direction: column; } }
  .General_list_hori__content ul li {
    display: flex;
    flex: 1;
    list-style: none;
    height: 140px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    letter-spacing: 0.6px;
    color: #ffffff;
    padding-left: 40px;
    padding-right: 40px; }

.Contact__content {
  display: flex;
  flex-direction: row; }
  @media (max-width: 991px) {
    .Contact__content {
      height: auto;
      flex-direction: column; } }
  .Contact__content__info {
    width: 50%;
    padding: 76px 111px 86px;
    text-align: center; }
    @media (max-width: 1199px) {
      .Contact__content__info {
        padding: 76px 60px 71px; } }
    @media (max-width: 991px) {
      .Contact__content__info {
        width: 100%;
        padding: 53px 40px 50px; } }
    .Contact__content__info__title {
      font-size: 30px;
      line-height: 39px;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 100px; }
      @media (max-width: 767px) {
        .Contact__content__info__title {
          margin-bottom: 43px; } }
    .Contact__content__info__text {
      font-size: 18px;
      line-height: 1.9;
      color: #ffffff; }
      .Contact__content__info__text ul {
        width: auto;
        display: inline-block; }
      .Contact__content__info__text p {
        margin-bottom: 0; }
      .Contact__content__info__text a:hover {
        color: #f08a01 !important; }
  .Contact__content__contact {
    width: 50%;
    padding: 0;
    display: flex;
    background-size: cover;
    background-position: center center; }
    @media (max-width: 991px) {
      .Contact__content__contact {
        width: 100%; } }
    .Contact__content__contact__holder {
      background: rgba(53, 71, 98, 0.6);
      width: 100%;
      padding: 76px 127px 71px; }
      @media (max-width: 1199px) {
        .Contact__content__contact__holder {
          padding: 76px 60px 71px; } }
      @media (max-width: 991px) {
        .Contact__content__contact__holder {
          padding: 23px 20px 24px; } }
    .Contact__content__contact input,
    .Contact__content__contact textarea {
      background: none;
      border: solid 2px #ffffff;
      font-size: 18px;
      letter-spacing: 0.6px;
      color: #ffffff;
      padding: 16px 16px;
      height: auto;
      line-height: 18px; }
    .Contact__content__contact textarea {
      height: 155px; }
    .Contact__content__contact__success_message {
      display: none;
      font-size: 30px;
      color: #fff; }
    .Contact__content__contact .has-error .help-block,
    .Contact__content__contact .has-error .control-label,
    .Contact__content__contact .has-error .radio,
    .Contact__content__contact .has-error .checkbox,
    .Contact__content__contact .has-error .radio-inline,
    .Contact__content__contact .has-error .checkbox-inline,
    .Contact__content__contact .has-error.radio label,
    .Contact__content__contact .has-error.checkbox label,
    .Contact__content__contact .has-error.radio-inline label,
    .Contact__content__contact .has-error.checkbox-inline label {
      color: #fff; }
    .Contact__content__contact .has-error .form-control {
      border-color: #ffffff;
      box-shadow: inset 0 1px 20px rgba(0, 0, 0, 0.075); }
  .Contact__content .Button_std__outer {
    display: flex;
    justify-content: center;
    text-align: center; }
  .Contact__content .Button_std__inner {
    display: flex;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    justify-content: center; }
    .Contact__content .Button_std__inner__text {
      display: inline-block; }

.Footer__content {
  display: flex;
  justify-content: space-between;
  padding: 35px 27px; }
  @media (max-width: 767px) {
    .Footer__content {
      flex-direction: column; } }
  .Footer__content * {
    opacity: 0.6;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff; }
    @media (max-width: 767px) {
      .Footer__content * {
        margin-bottom: 1.5em;
        text-align: center; } }
  .Footer__content a {
    margin-bottom: 0; }
  .Footer__content a:hover {
    color: #f08a01 !important; }

.General_text_image_grid__content {
  padding-top: 68px; }
  @media (max-width: 991px) {
    .General_text_image_grid__content {
      padding-top: 53px; } }
  .General_text_image_grid__content__title {
    line-height: 39px;
    font-weight: 500;
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 76px;
    text-align: center; }
    @media (max-width: 991px) {
      .General_text_image_grid__content__title {
        margin-bottom: 57px; } }
    @media (max-width: 767px) {
      .General_text_image_grid__content__title {
        margin-bottom: 43px; } }
  .General_text_image_grid__content__images {
    display: block;
    padding: 10px;
    margin-bottom: 0; }
    .General_text_image_grid__content__images .Image_grid_item {
      float: left;
      list-style: none;
      display: inline-block;
      width: 50%;
      height: 512px; }
      @media (max-width: 991px) {
        .General_text_image_grid__content__images .Image_grid_item {
          width: 100%;
          height: 512px; } }
      @media (max-width: 767px) {
        .General_text_image_grid__content__images .Image_grid_item {
          width: 100%;
          height: 340px; } }
      .General_text_image_grid__content__images .Image_grid_item__padding {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px; }
        .General_text_image_grid__content__images .Image_grid_item__padding__image {
          background-size: cover;
          background-position: center center;
          list-style: none;
          display: inline-block;
          width: 100%;
          height: 100%; }

.Services_index__content {
  height: 680px; }
  .Services_index__content ul {
    padding: 0;
    display: block;
    width: 100%; }
  .Services_index__content__item {
    list-style: none;
    float: left;
    width: 33.3334%; }
    @media (max-width: 991px) {
      .Services_index__content__item {
        width: 50%; } }
    @media (max-width: 767px) {
      .Services_index__content__item {
        width: 100%; } }
    .Services_index__content__item-top {
      display: block;
      height: 340px;
      background-size: cover;
      background-position: center center; }
    .Services_index__content__item-bottom {
      padding-top: 46px;
      padding-bottom: 47px;
      display: block;
      height: 340px; }
      @media (max-width: 991px) {
        .Services_index__content__item-bottom {
          height: auto; } }
      .Services_index__content__item-bottom__title {
        line-height: 39px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 38px; }
      .Services_index__content__item-bottom__text {
        font-size: 18px;
        line-height: 1.9;
        color: #ffffff;
        text-align: center;
        margin-bottom: 40px;
        height: 66px;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        display: block;
        padding-left: 37px;
        padding-right: 37px; }
        @media (max-width: 991px) {
          .Services_index__content__item-bottom__text {
            height: 99px; } }
        @media (max-width: 767px) {
          .Services_index__content__item-bottom__text {
            height: auto;
            max-height: 132px; } }
        .Services_index__content__item-bottom__text ul {
          width: auto;
          display: inline-block; }
        .Services_index__content__item-bottom__text p {
          margin-bottom: 0; }
        .Services_index__content__item-bottom__text a:hover {
          color: #f08a01 !important; }
