.General_title_text {

  &__content {
    display         : flex;
    text-align      : center;
    justify-content : center;
    padding-top     : 80px;
    padding-bottom  : 110px;
    flex-direction  : column;

    @media(max-width : 767px) {
      padding-top     : 56px;
      padding-bottom  : 60px;
    }

    &__title {
      display       : block;
      font-weight   : 500;
      font-size     : 30px;
      line-height   : 39px;
      color         : #ffffff;
      margin-bottom : 86px;
    }

    &__holder_text {
      display         : flex;
      justify-content : center;

      &__text {
        font-size   : 18px;
        line-height : 1.9;
        color       : #ffffff;
        width       : 50%;

        @media(max-width : 767px) {
          width: auto;
          padding-left: 22px;
          padding-right: 22px;
        }

        ul {
          width   : auto;
          display : inline-block;
        }

        p {
          margin-bottom: 0;
        }

        a:hover {
          color : $orange !important;
        }

      }

    }

  }

}