body {
  width  : 100%;
  height : 100%;
}

.slideout-menu {
  background-color           : #f08a01;
  position                   : fixed;
  left                       : auto;
  top                        : 0;
  bottom                     : 0;
  right                      : 0;
  z-index                    : 0;
  width                      : 275px;
  overflow-y                 : scroll;
  overflow-x                 : hidden;
  -webkit-overflow-scrolling : touch;
  display                    : none;
  box-shadow                 : -1px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.fa-angle-down {
  margin-top : 10px;
}

#slideout-menu--languages {
  background-color           : #f08a01;
  position                   : absolute;
  right                      : -275px;
  top                        : 0;
  width                      : 275px;
  overflow-y                 : auto;
  height                     : 100%;
  -webkit-overflow-scrolling : touch;
  display                    : none;
  z-index                    : 5;
}

.slideout-menu--open--languages:hover {
  cursor : pointer;
}

.slideout-panel-darkness {
  position         : absolute;
  z-index          : 1000;
  width            : 102%;
  height           : 100%;
  background-color : rgba(0, 0, 0, 0.8);
}

.slideout-panel {
  position         : relative;
  z-index          : 3;
  background-color : #ffffff;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow : hidden;
}

.slideout-open .slideout-menu {
  display : block;
}

.slideout-menu__header {
  width          : 100%;
  font-size      : 15px;
  color          : #ffffff;
  text-align     : right;
  padding-right  : 15px;
  height         : 50.5px;
  border-bottom  : 1px solid rgba(255, 255, 255, 0.3);
  position       : relative;
  text-transform : uppercase;
}

.slideout-menu__bottom {
  text-transform : uppercase;
  font-size      : 15px;
  color          : #ffffff;
  text-align     : right;
  padding-right  : 15px;
  bottom         : 0;
  right          : 0;
  width          : 100%;
  height         : 50px;
  border-top     : 1px solid rgba(255, 255, 255, 0.3);
}

.mobile-back {
  margin-right : 5px;
}

.icon-close {
  font-size      : 14px;
  vertical-align : -1px;
  position       : relative;
  font-weight    : 400;
}

.icon-close:hover {
  cursor : pointer;
}

.slideout-menu__items {
  text-decoration : none;
  width           : 100%;
  text-align      : right;
  padding-right   : 15px;
  text-transform  : uppercase;
}

.slideout-menu__item {
  list-style-type : none;
  font-weight     : 600;
  font-size       : 20px;
  color           : #ffffff;
  letter-spacing  : 1px;
}

.slideout-menu__item__link {
  word-wrap : break-word;
  color     : white;

  &:hover {
    color           : white;
    text-decoration : none;
  }
}

.slideout-menu__item:last-child {
  padding-bottom : 70px;
}

.slideout-menu__item:hover {
  color : #ffa726;
}

.slideout__menu__item--active {
  color : #ffa726;
}