.Services_index {

  &__content {
    height : 680px;
    //display        : flex;
    //flex-direction : row;

    ul {
      padding : 0;
      display : block;
      width   : 100%;
    }

    &__item {
      list-style : none;
      float      : left;
      width      : 33.3334%;

      @media(max-width : 991px) {
        width : 50%;
      }
      @media(max-width : 767px) {
        width : 100%;
      }

      &-top {
        display             : block;
        height              : 340px;
        background-size     : cover;
        background-position : center center;
      }

      &-bottom {
        padding-top    : 46px;
        padding-bottom : 47px;
        display        : block;
        height         : 340px;

        @media(max-width : 991px) {
          height : auto;
        }

        &__title {
          line-height    : 39px;
          font-weight    : 500;
          font-size      : 30px;
          letter-spacing : 1px;
          color          : #ffffff;
          text-align     : center;
          margin-bottom  : 38px;
        }

        &__text {
          font-size     : 18px;
          line-height   : 1.9;
          color         : #ffffff;
          text-align    : center;
          margin-bottom : 40px;
          height        : 66px;
          text-overflow : ellipsis;
          overflow      : hidden;
          position      : relative;
          display       : block;
          padding-left  : 37px;
          padding-right : 37px;

          @media(max-width : 991px) {
            height : 99px;
          }
          @media(max-width : 767px) {
            height     : auto;
            max-height : 132px;
          }

          ul {
            width   : auto;
            display : inline-block;
          }

          p {
            margin-bottom: 0;
          }

          a:hover {
            color : $orange !important;
          }

        }

      }

    }

  }

}
