/*
* Vendor extensions
*/

@import "vendor/bootstrap_extensions/carousel";
@import "vendor/bootstrap_extensions/contact";
@import "vendor/bootstrap_extensions/cover";
@import "vendor/bootstrap_extensions/gallery";
@import "vendor/bootstrap_extensions/general";



/*
* Utils
*/

@import "utils/utils";


/* 
* Global styling
*/

//@import "global/colors";
@import "global/fonts";
//@import "global/misc";
//@import "global/transitions";
//@import "global/general";
//@import "global/animate";

/*
* Components
*/

//@import "components/columns/image-title-column-readmore";
//@import "components/columns/pic-text";
//@import "components/columns/title-images-column";
//@import "components/columns/title-text-button-column";
//@import "components/columns/title-text-column";
//@import "components/columns/image-text";
//@import "components/columns/title-5x-text-column.scss";
//@import "components/columns/halfcolor-image-halfcolor.scss";
//@import "components/columns/title-4img-4img";
//
//@import "components/headers/background_image";
//@import "components/headers/background_image_title";
//@import "components/headers/background-video-image";
//@import "components/headers/search-results-title";
//
//@import "components/images/almost-full-width-image";
//@import "components/images/center-image-button";
//
//


//@import "components/bootstrap-pagination";
//@import "components/button-dropdown";
//@import "components/catalogus-downloads-block";
//@import "components/contact-form";
//@import "components/floating_images";
//@import "components/full-width-image";
//@import "components/moving_arrow";
//@import "components/news-block";
//@import "components/owl-carousel";
//@import "components/slider";
//@import "components/photoswipe";
//@import "components/google-maps";
//@import "components/coworkers-team";
//@import "components/title-dual-text-image";
//@import "components/title-image-text-container";
//@import "components/title-logo-text-container";
//@import "components/title-subtitle";


/*
* Client-specific styling
*/

//@import "clientspecific/superpage";
//@import "clientspecific/about-us";
//@import "clientspecific/contact";
//@import "clientspecific/downloads";
//@import "clientspecific/footer";
//@import "clientspecific/homepage";
//@import "clientspecific/navbar";
//@import "clientspecific/news_block_item";
//@import "clientspecific/news_detail";
//@import "clientspecific/news_overview";
//@import "clientspecific/product-categories";
//@import "clientspecific/product_detail";
//@import "clientspecific/product_list";
//@import "clientspecific/productmarktcombinaties";
//@import "clientspecific/project-detail";
//@import "clientspecific/projects";
//@import "clientspecific/search_results";
//@import "clientspecific/wysiwyg-text-image";
//@import "clientspecific/services";
//@import "clientspecific/sub_services";
//@import "clientspecific/sub_services_detail";
//@import "clientspecific/newslister";

//@import "clientspecific/center-image-button";

@import "vendor/page_preloader/effect1";

@import "vendor/slideout";

@import "components/cookies";

@import "global/spuitwerkspecialist";
@import "components/user-defined/topbar";
@import "components/user-defined/banner-media";
@import "components/user-defined/general-title-text";
@import "components/user-defined/service";
@import "components/user-defined/general-text-cta";
@import "components/user-defined/general-text-full";
@import "components/user-defined/general_list_hori";
@import "components/user-defined/contact";
@import "components/user-defined/footer";
@import "components/user-defined/general-text-image-grid";
@import "components/user-defined/services-index";


