.General_list_hori {

  &__content {

    ul {
      display        : flex;
      flex-direction : row;
      padding        : 0;
      margin-bottom: 0;

      @media(max-width : 991px) {
        flex-direction : column;
      }

      li {
        display         : flex;
        flex            : 1;
        list-style      : none;
        height          : 140px;
        text-align      : center;
        justify-content : center;
        align-items     : center;
        font-size       : 18px;
        letter-spacing  : 0.6px;
        color           : #ffffff;
        padding-left    : 40px;
        padding-right   : 40px;
      }
    }
  }
}