$orange : #f08a01;

.container,
.container-fluid {
  padding-top    : 15px;
  padding-bottom : 40px;
  font-family    : Roboto;
  font-weight    : 400;

  & > .row > div {
    padding-left  : 40px;
    padding-right : 40px;
  }

  @media(max-width : 991px) {
    padding-top    : 5px;
    padding-bottom : 20px;

    & > .row > div {
      padding-left  : 20px;
      padding-right : 20px;
    }
  }
}

// DEFAULT SET FOR LINKS
p a,
ul a {
  color : $orange;
}

// STANDARD BUTTON
.Button_std {

  &__outer {
    display         : flex;
    justify-content : center;

    &:hover {
      text-decoration : none;
    }
  }

  &__inner {
    background    : $orange;
    padding-left  : 47px;
    padding-right : 47px;
    height        : 60px;
    display       : inline-block;
    transition    : all 0.5s ease-in-out;

    &:hover {
      text-decoration : none;
      background      : #c37000;
    }

    @media(max-width : 991px) {
      padding-left  : 27px;
      padding-right : 27px;
    }

    &__text {
      width           : 100%;
      height          : 100%;
      align-items     : center;
      display         : flex;
      font-weight     : 500;
      font-size       : 18px;
      letter-spacing  : 0.6px;
      color           : #ffffff;
      cursor          : pointer;
      background      : none;
      border          : none;
      outline         : 0;
      text-align      : center;
      justify-content : center;

      &:hover {
        text-decoration : none;
      }

    }

  }
}

// SLIDEOUT MENU
.icon-menu {
  color  : #2a384e;
  cursor : pointer;
}

.icon-menu:hover {
  color  : $orange;
  cursor : pointer;
}

#slideout-menu {
  background-color : #2a384e;

  .slideout-menu__items {
    padding-right : 0;
  }

  .slideout-menu__item {
    padding-right : 18px;

    a {
      color       : #fff;
      display     : block;
      padding-top : 15px;
    }

  }

  .slideout__menu__item--active {

    a {
      color : $orange;
    }

  }

  .mobile-back {
    cursor : pointer;
  }

  .mobile-back:hover {
    color : $orange;
  }

}

// PAGE LOADER
.ip-header {
  background  : $orange;
  display     : flex;
  align-items : center;
}

.ip-logo-holder {
  display         : flex;
  width           : 100%;
  text-align      : center;
  align-items     : center;
  height          : auto;
  justify-content : center;
}

.ip-logo {
  height   : auto;
  width    : 40%;
  position : relative;
  display  : inline-block;

  @media(max-width : 991px) {
    width : 50%;
  }

  @media(max-width : 767px) {
    width : 60%;
  }
}

.ip-logo, .ip-loader {
  opacity : 1;
}

.ip-header .ip-loader svg path.ip-loader-circle {
  stroke : #fff;
}

.ip-main h2 {
  color : #fff;
}


