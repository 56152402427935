.General_text_cta {

  &__content {
    padding: 80px 34px 93px 34px;

    @media(max-width : 767px) {
      padding-top: 53px;
      padding-bottom: 39px;
    }

    &__text {
      text-align     : center;
      line-height    : 1.9;
      font-size      : 30px;
      letter-spacing : 1px;
      color          : #ffffff;
      margin-bottom  : 91px;

      ul {
        width   : auto;
        display : inline-block;
      }

      p {
        margin-bottom: 0;
      }

      a:hover {
        color : $orange !important;
      }

      @media(max-width : 767px) {
        margin-bottom: 60px;
      }

    }

  }

}