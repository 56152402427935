.General_text_image_grid {

  &__content {
    padding-top : 68px;

    @media(max-width : 991px) {
      padding-top : 53px;
    }

    &__title {
      line-height   : 39px;
      font-weight   : 500;
      font-size     : 30px;
      color         : #ffffff;
      margin-bottom : 76px;
      text-align    : center;

      @media(max-width : 991px) {
        margin-bottom : 57px;
      }
      @media(max-width : 767px) {
        margin-bottom : 43px;
      }
    }

    &__images {
      display       : block;
      padding       : 10px;
      margin-bottom : 0;

      .Image_grid_item {
        float      : left;
        list-style : none;
        display    : inline-block;
        width      : 50%;
        height     : 512px;

        @media(max-width : 991px) {
          width  : 100%;
          height : 512px;
        }
        @media(max-width : 767px) {
          width  : 100%;
          height : 340px;
        }

        &__padding {
          display : inline-block;
          width   : 100%;
          height  : 100%;
          padding : 10px;

          &__image {
            background-size     : cover;
            background-position : center center;
            list-style          : none;
            display             : inline-block;
            width               : 100%;
            height              : 100%;
          }
        }
      }

    }

  }

}