.Topbar {

  &__content {
    height          : 79px;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    &__logo-holder {
      max-width : 58%;
    }

    &__logo {
      height : 41px;
      width  : 360px;

      img {
        max-height : 35px;
      }

    }

    &__menu {
      height    : 41px;
      min-width : 40px;
      display   : flex;

      ul {
        display        : flex;
        flex-direction : row;
        padding        : 0;
        align-items    : center;
        margin         : 0;

        li {
          padding-left : 60px;
          list-style   : none;

          a {
            font-size : 16px;
            color     : rgba(53, 71, 98, 0.5);
          }

          a:hover {
            color : #354762;
            text-decoration: none;
          }
        }

        li.active {

          a {
            color : $orange;
          }

        }

      }

      &__telephone {
        font-size : 16px;
        color     : $orange;
      }
    }
  }
}