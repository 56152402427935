.Contact {

  &__content {
    display        : flex;
    flex-direction : row;

    @media(max-width : 991px) {
      height         : auto;
      flex-direction : column;
    }

    &__info {
      width      : 50%;
      padding    : 76px 111px 86px;
      text-align : center;

      @media(max-width : 1199px) {
        padding : 76px 60px 71px;
      }
      @media(max-width : 991px) {
        width   : 100%;
        padding : 53px 40px 50px;
      }

      &__title {
        font-size      : 30px;
        line-height    : 39px;
        letter-spacing : 1px;
        color          : #ffffff;
        margin-bottom  : 100px;

        @media(max-width : 767px) {
          margin-bottom : 43px;
        }

      }

      &__text {
        font-size   : 18px;
        line-height : 1.9;
        color       : #ffffff;

        ul {
          width   : auto;
          display : inline-block;
        }

        p {
          margin-bottom : 0;
        }

        a:hover {
          color : $orange !important;
        }

      }

    }

    &__contact {
      width               : 50%;
      padding             : 0;
      display             : flex;
      background-size     : cover;
      background-position : center center;

      @media(max-width : 991px) {
        width : 100%;
      }

      &__holder {
        background : rgba(53, 71, 98, 0.6);
        width      : 100%;
        padding    : 76px 127px 71px;

        @media(max-width : 1199px) {
          padding : 76px 60px 71px;
        }
        @media(max-width : 991px) {
          padding : 23px 20px 24px;
        }
      }

      input,
      textarea {
        background     : none;
        border         : solid 2px #ffffff;
        font-size      : 18px;
        letter-spacing : 0.6px;
        color          : #ffffff;
        padding        : 16px 16px;
        height         : auto;
        line-height    : 18px;
      }

      textarea {
        height : 155px;
      }

      &__success_message {
        display   : none;
        font-size : 30px;
        color     : #fff;
      }

      // ERRORS
      .has-error .help-block,
      .has-error .control-label,
      .has-error .radio,
      .has-error .checkbox,
      .has-error .radio-inline,
      .has-error .checkbox-inline,
      .has-error.radio label,
      .has-error.checkbox label,
      .has-error.radio-inline label,
      .has-error.checkbox-inline label {
        color : #fff;
      }

      .has-error .form-control {
        border-color : #ffffff;
        box-shadow   : inset 0 1px 20px rgba(0, 0, 0, 0.075);
      }

    }

    .Button_std {

      &__outer {
        display         : flex;
        justify-content : center;
        text-align      : center;
      }

      &__inner {
        display         : flex;
        width           : 100%;
        padding-left    : 0;
        padding-right   : 0;
        text-align      : center;
        justify-content : center;

        &__text {
          display : inline-block;
        }

      }

    }

  }

}

