.General_text_full {

  &__content {
    padding-top    : 84px;
    padding-bottom : 78px;
    text-align     : center;

    @media(max-width : 767px) {
      padding-top    : 70px;
      padding-bottom : 69px;
    }

    &__holder_text {
      display         : flex;
      justify-content : center;

      &__text {
        width          : 50%;
        line-height    : 1.9;
        font-size      : 30px;
        letter-spacing : 1px;
        color          : #ffffff;

        ul {
          width   : auto;
          display : inline-block;
        }

        p {
          margin-bottom: 0;
        }

        a:hover {
          color : $orange !important;
        }

        @media(max-width : 767px) {
          width: 80%;
          line-height: 1.5;
        }

      }

    }

  }

}