@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?1tn30a');
    src:    url('../fonts/icomoon.eot?1tn30a#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff2?1tn30a') format('woff2'),
        url('../fonts/icomoon.ttf?1tn30a') format('truetype'),
        url('../fonts/icomoon.woff?1tn30a') format('woff'),
        url('../fonts/icomoon.svg?1tn30a#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e900";
}
.icon-menu:before {
    content: "\e901";
}


