.Footer {

  &__content {
    display         : flex;
    justify-content : space-between;
    padding         : 35px 27px;

    @media(max-width : 767px) {
      flex-direction: column;
    }

    * {
      opacity     : 0.6;
      font-size   : 14px;
      line-height : 18px;
      color       : #ffffff;

      @media(max-width : 767px) {
        margin-bottom: 1.5em;
        text-align: center;
      }
    }

    a {
      margin-bottom: 0;
    }

    a:hover {
      color : $orange !important;
    }

  }

}