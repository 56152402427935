.ip-header {
  position   : fixed;
  top        : 0;
  z-index    : 100;
  width      : 100%;
  height     : 100%;
  background : #f1f1f1;

  .ip-loader {
    bottom : 20%;
  }

  .ip-inner {
    display : block;
    margin  : 0 auto;
  }

  .ip-logo svg {
    min-width : 320px;
    max-width : 480px;
    width     : 25%;
  }

  .ip-logo svg path {
    fill : #ef6e7e;
  }

  .ip-loader svg path {
    fill         : none;
    stroke-width : 6;
  }

  .ip-loader svg path.ip-loader-circlebg {
    stroke : #ddd;
  }

  .ip-loader svg path.ip-loader-circle {
    -webkit-transition : stroke-dashoffset 0.2s;
    transition         : stroke-dashoffset 0.2s;
    stroke             : #ef6e7e;
  }
}

.ip-logo,
.ip-loader {
  position       : absolute;
  left           : 0;
  width          : 100%;
  opacity        : 0;
  cursor         : default;
  pointer-events : none;
}

.ip-logo {
  top               : 0;
  height            : 100%;
  -webkit-transform : translate3d(0, 25%, 0);
  transform         : translate3d(0, 25%, 0);
}

/* Animations */
/* Initial animation of header elements */
.ip-container.loading .ip-logo,
.ip-container.loading .ip-loader {
  opacity           : 1;
  -webkit-animation : animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
  animation         : animInitialHeader 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}

.ip-container.loading .ip-loader {
  -webkit-animation-delay : 0.2s;
  animation-delay         : 0.2s;
}

@-webkit-keyframes animInitialHeader {
  from {
    opacity           : 0;
    -webkit-transform : translate3d(0, 800px, 0);
  }
}

@keyframes animInitialHeader {
  from {
    opacity           : 0;
    -webkit-transform : translate3d(0, 800px, 0);
    transform         : translate3d(0, 800px, 0);
  }
}

/* Header elements when loading finishes */
.ip-container.loaded .ip-logo,
.ip-container.loaded .ip-loader {
  opacity : 1;
}

.ip-container.loaded .ip-logo {
  -webkit-transform-origin : 50% 0;
  transform-origin         : 50% 0;
  -webkit-animation        : animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation                : animLoadedLogo 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@-webkit-keyframes animLoadedLogo {
  to {
    -webkit-transform : translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
  }
}

@keyframes animLoadedLogo {
  to {
    -webkit-transform : translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
    transform         : translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
  }
}

.ip-container.loaded .ip-loader {
  -webkit-animation : animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation         : animLoadedLoader 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@-webkit-keyframes animLoadedLoader {
  to {
    opacity           : 0;
    -webkit-transform : translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
  }
}

@keyframes animLoadedLoader {
  to {
    opacity           : 0;
    -webkit-transform : translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
    transform         : translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
  }
}

/* Change the color of the logo */
.ip-container.loaded .ip-logo svg path {
  -webkit-transition : all 0.5s ease 0.3s;
  transition         : all 0.5s ease 0.3s;
  fill               : #fff;
}

/* Header animation when loading finishes */
.ip-container.loaded .ip-header {
  -webkit-animation : animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation         : animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@-webkit-keyframes animLoadedHeader {
  to {
    -webkit-transform : translate3d(0, -100%, 0);
  }
}

@keyframes animLoadedHeader {
  to {
    -webkit-transform : translate3d(0, -100%, 0);
    transform         : translate3d(0, -100%, 0);
  }
}

/* Change layout class for header */
.layout-switch .ip-header {
  position : absolute;
}

/* No JS */
.no-js .ip-header {
  position   : relative;
  min-height : 0px;
}

.no-js .ip-header .ip-logo {
  margin-top        : 20px;
  height            : 180px;
  opacity           : 1;
  -webkit-transform : none;
  transform         : none;
}

.no-js .ip-header .ip-logo svg path {
  fill : #fff;
}

/* Media queries */

@media screen and (max-width : 45em) {

  .ip-main h2 {
    font-size : 2.25em;
    font-size : 10vw;
  }

  .box {
    width : 100%;
  }

}