.Cookies {
  // Positioning.
  position: fixed;
  bottom: 0;
  z-index: 9001;

  // Metrics.
  width: 100%;
  padding: 2em;

  // Styling.
  background-color: rgba(64, 64, 64, 0.8);

  // Font styling.
  text-align: center;
  color: white;
  text-shadow: 0 0 4px #000;
}

.Cookies__text {
}

.Cookies__accept-button {
  // Positioning.
  //float: right;

  // Styling.
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;

  &:hover {
    background-color: #fff;
  }

  // Font styling.
  color: #fff;

  &:focus {
    color: #fff;
  }

  &:hover {
    color: #000;
  }
}